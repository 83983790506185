'use client';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.NEXT_PUBLIC_DATADOG_ENV) {
  datadogRum.init({
    applicationId: '7d25ad43-1858-4403-bfac-a9928d63d2c3',
    clientToken: 'pub83971f1a1d7249d936c631482711210e',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    version: process.env.NEXT_PUBLIC_COMMIT_HASH,
    site: 'us5.datadoghq.com',
    service: 'emporia-electrification-store',
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}
export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
