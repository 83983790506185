import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src676944848/src/electrification-store-client/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src676944848/src/electrification-store-client/src/app/components/body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src676944848/src/electrification-store-client/src/app/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src676944848/src/electrification-store-client/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/codebuild/output/src676944848/src/electrification-store-client/src/app/theme.ts");
