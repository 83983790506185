'use client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AlertProvider } from '@/app/components/alertProvider';
import React from 'react';

export default function Providers({
  children
}: React.PropsWithChildren): React.ReactElement {
  return (
    <>
      <AlertProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {children}
        </LocalizationProvider>
      </AlertProvider>
    </>
  );
}
